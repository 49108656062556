<div class="row mb-3">
  <div
    class="col-sm-6"
    style="display: flex; align-items: center; justify-content: start"
  >
    <h5 class="m-0">Vendors</h5>
  </div>
  <div
    class="col-sm-6"
    style="display: flex; align-items: center; justify-content: end"
  >
    <button
      mat-flat-button
      color="primary"
      mat-button
      class="bg-primary text-white"
      aria-label="add"
      (click)="navigateById('new')"
    >
      <mat-icon>add</mat-icon> Create Vendor
    </button>
  </div>
</div>

<app-loading *ngIf="!dataLoaded"></app-loading>
<div *ngIf="dataLoaded">
  <div class="filter-container mb-2 mt-3">
    <div class="filter-icon">
      <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
    </div>
    <input
      type="text"
      placeholder="Search Name..."
      class="form-control text-input"
      [(ngModel)]="filterName"
      (ngModelChange)="search()"
      style="
        min-width: 13rem;
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
      "
    />
    <input
      type="text"
      placeholder="Search Location..."
      class="form-control text-input"
      [(ngModel)]="filterLocation"
      (ngModelChange)="search()"
      style="
        min-width: 13rem;
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
      "
    />
    <input
      type="text"
      placeholder="Search Category..."
      class="form-control text-input"
      [(ngModel)]="filterCategory"
      (ngModelChange)="search()"
      style="
        min-width: 13rem;
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
      "
    />
    <input
      type="text"
      placeholder="Search Sector..."
      class="form-control text-input"
      [(ngModel)]="filterSector"
      (ngModelChange)="search()"
      style="
        min-width: 13rem;
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
      "
    />
    <input
      type="text"
      placeholder="Search Area expertise..."
      class="form-control text-input"
      [(ngModel)]="filterArea"
      (ngModelChange)="search()"
      style="
        min-width: 13rem;
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
      "
    />
    <input
      type="text"
      placeholder="Search Type..."
      class="form-control text-input"
      [(ngModel)]="filterType"
      (ngModelChange)="search()"
      style="
        min-width: 13rem;
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
      "
    />
    <mat-form-field
      class="w-100 filter-dropdown"
      appearance="outline"
      style="font-size: 12px"
    >
      <mat-select
        [(ngModel)]="filterVendorType"
        (ngModelChange)="search()"
        placeholder="Search Vendor Type..."
      >
        <mat-option value=""></mat-option>
        <mat-option *ngFor="let type of vendorTypeList" [value]="type.id">{{
          type.display_name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field
      class="w-100 filter-dropdown"
      appearance="outline"
      style="font-size: 12px"
    >
      <mat-select
        [(ngModel)]="filterStatus"
        (ngModelChange)="search()"
        placeholder="Search Status..."
      >
        <mat-option value=""></mat-option>
        <mat-option
          *ngFor="let status of vendorStatusList"
          [value]="status.id"
          >{{ status.display_name }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <input
      type="text"
      placeholder="Search Vendor Tier..."
      class="form-control text-input"
      [(ngModel)]="filterTier"
      (ngModelChange)="search()"
      style="
        min-width: 13rem;
        border-radius: 8px;
        font-size: 12px;
        height: 40px;
      "
    />
  </div>

  <div style="overflow-x: hidden">
    <div class="mt-4">
      <div class="flex-container-1">
        <app-loading
          style="width: 100%; min-height: 200px"
          *ngIf="!vendorList"
        ></app-loading>

        <div class="flex-item p-1" *ngFor="let x of vendorList">
          <div
            class="trn-x"
            [ngStyle]="{ background: x.color }"
            (click)="navigateById(x.id)"
          >
            <div style="display: flex; align-items: center; height: 50px">
              <img [src]="x.url" class="is-img" *ngIf="x.url" />
              <img
                src="assets/images/No_image_available.webp"
                class="is-img"
                *ngIf="!x.url"
              />
              <h6 class="mb-0 trn-nm">{{ x.name }}</h6>
            </div>
            <p class="mb-1" style="font-size: 9px; margin-top: 10px">
              Contact
              <span
                style="
                  display: block;
                  width: 100%;
                  font-weight: 700;
                  font-size: 12px;
                "
              >
                <span *ngIf="x.primary_contact_name || x.primary_contact_email"
                  >{{ x.primary_contact_name }}
                  <br *ngIf="x.primary_contact_email" />
                  {{ x.primary_contact_email }}
                </span>
                <span
                  *ngIf="
                    !x.primary_contact_name &&
                    !x.primary_contact_email &&
                    !x.primary_contact
                  "
                  >Not Available</span
                >
              </span>
            </p>
            <p class="mb-1" style="font-size: 9px; margin-top: 10px">
              Domain
              <span
                style="
                  display: block;
                  width: 100%;
                  font-weight: 700;
                  font-size: 12px;
                "
              >
                {{ x.domain_name }}
                <span *ngIf="!x.domain_name"> Not Available</span>
              </span>
            </p>
          </div>

          <button
            mat-icon-button
            class="action-vert"
            color="warn"
            (click)="deleteVendor(x.id, i); $event.stopPropagation()"
          >
            <mat-icon style="font-size: 15px; color: red">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="custom-table fixed-table-header" style="max-height: calc(100vh - 250px);">
      <table class="table table-bordered text-left">
          <thead>
              <tr>
                  <th>Name</th>
                  <th>Primary Name / Contact</th>
                  <th>Short Name</th>
                  <th>Domain Name</th>
                  <th>Action</th>
  
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of vendorList">
                  <td>{{item.name}}</td>
                  <td>{{item.primary_contact_name}} <br> {{item.primary_contact_email}}</td>
                  <td>{{item.short_name}}</td>
                  <td>{{item.domain_name}}</td>
                  <td class="text-center pt-1 pb-1">
                      <div class="d-flex justify-content-between">
                          <mat-icon aria-hidden="false" class="cursor-pointer fs-2" (click)="navigateById(item.id)">
                              edit_note
                          </mat-icon>
                          <img (click)="deleteVendor(item.id)" class="cursor-pointer"
                              src="./assets/images/comment-delete.svg" style="width:16px;">
                      </div>
                  </td>
              </tr>
          </tbody>
          <ng-container *ngIf="vendorList?.count == 0">
              <div class="noRecords d-flex justify-content-center text-center">
                  <span>
                      {{staticText.common.no_records_found}}
                  </span>
              </div>
          </ng-container>
      </table>
  </div> -->
  <!-- <div class="d-flex  align-items-center justify-content-between" *ngIf="vendorList?.length > 0">
      <div class="p-1">
          <div
              class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark">
              <span class="p-1">Displaying</span>
              <select class="form-select form-select-sm table-sort-filter"
                  aria-label="Default select example" [(ngModel)]="sortRecords"
                  (ngModelChange)="getRateRecords(0)">
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
              </select>
              <span class="p-1">{{vendorList?.count}}</span>
              <span class="p-1">records</span>
          </div>
      </div>
      <div class="p-1" >
          <ngb-pagination [pageSize]="sortRecords" [collectionSize]="vendorList?.length" [maxSize]="2"
              [rotate]="true" [(page)]="CurrentRatePage" [boundaryLinks]="true"
              (pageChange)="getRateRecords(0);">
          </ngb-pagination>
      </div>
  </div> -->
</div>
