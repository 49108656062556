import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class VendorMasterService {
  constructor(private http: HttpClient) {}

  getVendorData() {
    return this.http.get("vendor/");
  }

  getVendor(id: any) {
    return this.http.get(`vendor/${id}/`);
  }

  createVendor(payload: any) {
    return this.http.post("vendor/", payload);
  }

  updateVendor(payload: any, id: any) {
    return this.http.post(`vendor/`, payload);
  }

  deleteUsers(userId: any) {
    return this.http.delete(`vendoruser/${userId}/`);
  }

  deleteInvestments(invId: any) {
    return this.http.delete(`investment/${invId}/`);
  }

  deleteVendor(payload: any, invId: any) {
    return this.http.patch(`vendor/${invId}/`, payload);
  }

  getVendorTypes() {
    return this.http.get(`base_value/?type=vendor_type`);
  }

  getVendorStatus() {
    return this.http.get(`base_value/?type=vendor_status`);
  }

  getFilteredVendors(
    name: any,
    category: any,
    location: any,
    sector: any,
    area: any,
    type: any,
    vendorType: any,
    status: any,
    tier: any
  ) {
    return this.http.get(
      `vendor/?search=category:${category},name:${name},location:${location},area:${area},type:${type},vendor_type:${vendorType},vendor_tier:${tier},status:${status},sector:${sector}`
    );
  }

  getAllVendorContracts(id: any) {
    return this.http.get(`vendor/${id}/vendor-contract/`);
  }

  getContracts(id: any, startValue: any, EndValue: any) {
    return this.http.get(
      `vendor/${id}/vendor-contract/?start=${startValue}&end=${EndValue}`
    );
  }
}
