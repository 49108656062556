import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListComponent } from './list/list.component';
import { IndexComponent } from './index/index.component';
import { CreateVendorComponent } from './create-vendor/create-vendor.component';

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    data: { breadcrumb: 'IPBD' },
    children: [
      {
        path: '',
        component: ListComponent,
        data: { breadcrumb: 'List' },
      },
      {
        path: 'create-vendor/:id',
        data: { breadcrumb: 'Create Vendor' },
        component: CreateVendorComponent
      }
    ]
  },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorMasterRoutingModule { }
