import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ChallengeWorkbenchService } from "src/app/shared/services/challenge-workbench.service";
import { NgxSpinnerService } from "ngx-spinner";
import { TagsService } from "src/app/shared/services/tag.service";
import { ActivatedRoute, Router } from "@angular/router";
import { VendorMasterService } from "src/app/shared/services/vendor-master.service";
import moment from "moment";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";
import * as textConfiguration from "src/assets/static-text-configuration.json";

@Component({
  selector: "app-create-vendor",
  templateUrl: "./create-vendor.component.html",
  styleUrls: ["./create-vendor.component.scss"],
})
export class CreateVendorComponent implements OnInit {
  manageVendorTags: any = [];
  vendorForm: FormGroup | any;
  allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
  imageUrl: string | ArrayBuffer | null | any = null;
  spId: any;
  email: any;
  panelOpenState = false;
  imageFile: any;
  type: any = "Creation";
  vendorTypeList: any = [];
  vendorStatusList: any = [];
  headerList: any = [
    "#",
    "Contract Name",
    "Contract Type",
    "Invoice Type",
    "Contract Value",
    "Enterprise Contract Id",
    "Director GM",
    "Start Date",
    "End Date",
  ];
  staticText: any = (textConfiguration as any).default;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  firstTimeLoad: boolean = true;
  contractsList: any = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private vms: VendorMasterService,
    private spinner: NgxSpinnerService,
    private tagService: TagsService,
    private route: ActivatedRoute,
    private breadCrumService: BreadcrumbService,
    private vendorMasterService: VendorMasterService
  ) {}

  ngOnInit(): void {
    this.vendorForm = this.fb.group({
      id: [""],
      name: ["", Validators.required],
      primary_contact_name: ["", Validators.required],
      primary_contact_email: ["", Validators.required],
      domain_name: ["", Validators.required],
      short_name: ["", Validators.required],
      users: this.fb.array([]),
      investments: this.fb.array([]),
      tags: [],
      type: [""],
      year: [""],
      year_of_incorp: [""],
      location: [""],
      nearest_hub: [""],
      sector: [""],
      profile: [""],
      founder_name: [""],
      founder_email: [""],
      contact_no: [""],
      email: [""],
      category: [""],
      area_expertise: [""],
      link: [""],
      // contact_email: [''],
      // contact_number: [''],
      is_gdg: [false],
      vendor_type: [""],
      vendor_tier: [""],
      status: [""],
      tax_number_1: [""],
      tax_number_2: [""],
      tax_number_3: [""],
      company_code: [""],
      accounting_info: [""],
      payment_term: [""],
      primary_contact_phone: [""],
      secondary_contact_name: [""],
      secondary_contact_email: [""],
      secondary_contact_phone: [""],
    });

    this.route.paramMap.subscribe((res: any) => {
      this.spId = atob(res.get("id"));
      if (this.spId != "new") {
        this.type = "Update";
        this.getVendor();
        this.getData();
      }
    });
    let breadCrumb = [
      { label: "Masters", url: "/prism/masters" },
      { label: "Vendor Master", url: "/vendor-master" },
      { label: this.type, url: "" },
    ];
    this.breadCrumService.setBreadcrumb(breadCrumb);
    this.getOptionsList();
  }

  getAllContracts() {
    this.vendorMasterService
      .getAllVendorContracts(this.spId)
      .subscribe((resp: any) => {
        this.contractsList = resp.records;
      });
  }

  getVendorTypeList() {
    this.vendorMasterService.getVendorTypes().subscribe((resp: any) => {
      this.vendorTypeList = resp;
    });
  }

  getVendorStatusList() {
    this.vendorMasterService.getVendorStatus().subscribe((resp: any) => {
      this.vendorStatusList = resp;
    });
  }

  getOptionsList() {
    this.getVendorTypeList();
    this.getVendorStatusList();
  }

  getVendor() {
    this.vms.getVendor(this.spId).subscribe(async (res: any) => {
      if (res) {
        this.vendorForm.patchValue(res);
        this.imageUrl = res.url;
        res.users.forEach((element: any) => {
          this.vendorForm.controls.users.controls.push(
            this.fb.group({
              id: new FormControl(element.id),
              user_id: new FormControl(element.user_id),
              first_name: new FormControl(element.first_name, [
                Validators.required,
              ]),
              last_name: new FormControl(element.last_name, [
                Validators.required,
              ]),
              email: new FormControl(element.email, [Validators.required]),
            })
          );
        });

        res.investments.forEach((element: any) => {
          this.vendorForm.controls.investments.controls.push(
            this.fb.group({
              id: new FormControl(element.id),
              description: new FormControl(element.description, [
                Validators.required,
              ]),
              valid_till: new FormControl(element.valid_till, [
                Validators.required,
              ]),
              amount: new FormControl(element.amount, [Validators.required]),
            })
          );
        });
      }
    });
  }

  getChildTags(data: any) {
    this.manageVendorTags = data;
  }
  navigateTo(url: any) {
    this.router.navigateByUrl(url);
  }

  onFileSelected(event: any) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.files && inputElement.files.length > 0) {
      const file = inputElement.files[0];
      if (!this.allowedImageTypes.includes(file.type)) {
        this.toastrService.error(
          "Invalid file type. Please select an image file."
        );
        return;
      }
      if (file.size > 5000000) {
        this.toastrService.error("Please upload less than 5MB");
        return;
      }
      this.loadImageAsBase64(file);
      this.imageFile = file;
    }
  }
  loadImageAsBase64(file: File): void {
    const reader = new FileReader();

    reader.onload = (e) => {
      this.imageUrl = e.target?.result;
    };

    reader.readAsDataURL(file);
  }

  submitVendor() {
    console.log(this.vendorForm);
    this.vendorForm.markAllAsTouched();
    if (this.vendorForm.status === "INVALID") {
      return;
    }
    this.spinner.show();
    const formData = new FormData();
    var data = this.vendorForm.value;
    let arr: any = [];
    this.vendorForm?.controls?.users.controls.forEach((element: any) => {
      arr.push(element.value);
    });
    data.users = arr;

    let arr1: any = [];
    this.vendorForm?.controls?.investments.controls.forEach((element: any) => {
      element.value.valid_till = moment(element.value.valid_till).format(
        "YYYY-MM-DD"
      );
      arr1.push(element.value);
    });
    data.investments = arr1;

    formData.append("data", JSON.stringify(data));
    if (typeof this.imageFile === "object") {
      formData.append("logo", this.imageFile);
    }
    if (this.spId == "new") {
      this.createNewVendor(formData);
    } else {
      this.updateVendor(formData);
    }
  }

  createNewVendor(formData: any) {
    this.vms.createVendor(formData).subscribe(async (res: any) => {
      if (res) {
        this.spinner.hide();
        this.toastrService.success("Vendor Created Successfully.");
        await this.addTag(res.id);
        this.router.navigate(["vendor-master/create-vendor/" + btoa(res.id)]);
      }
    });
  }

  updateVendor(formData: any) {
    this.vms.updateVendor(formData, this.spId).subscribe(async (res: any) => {
      if (res) {
        this.spinner.hide();
        this.toastrService.success("Vendor Updated Successfully.");
        this.addTag(res.id);
      }
    });
  }

  async addTag(id: any) {
    if (this.manageVendorTags?.length > 0) {
      await this.manageVendorTags.forEach(async (element: any) => {
        let pst = {
          entity_id: id,
          entity_type: "vendor",
          tag_id: element.id,
        };
        await this.tagService.magTag(pst).subscribe((res: any) => {});
      });
    }
  }

  getTagObject() {
    return {
      entity_id: this.spId,
      entity_type: "vendor",
    };
  }

  addUsers() {
    this.vendorForm.controls.users.controls.push(
      this.fb.group({
        user_id: new FormControl(""),
        first_name: new FormControl("", [Validators.required]),
        last_name: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.required]),
      })
    );
  }

  removeUsers(index: any) {
    if (this.vendorForm.get("users").controls[index].value.id) {
      this.spinner.show();
      let id = this.vendorForm.get("users").controls[index].value.id;
      this.vms.deleteUsers(id).subscribe((res: any) => {
        this.spinner.hide();
      });
    }
    this.vendorForm.get("users").removeAt(index);
  }

  addInvestment() {
    this.vendorForm.controls.investments.controls.push(
      this.fb.group({
        id: new FormControl(""),
        description: new FormControl("", [Validators.required]),
        valid_till: new FormControl("", [Validators.required]),
        amount: new FormControl("", [Validators.required]),
      })
    );
  }

  removeInvestment(index: any) {
    if (this.vendorForm.get("investments").controls[index].value.id) {
      this.spinner.show();
      let id = this.vendorForm.get("investments").controls[index].value.id;
      this.vms.deleteInvestments(id).subscribe((res: any) => {
        this.spinner.hide();
      });
    }
    this.vendorForm.get("investments").removeAt(index);
  }

  getData() {
    this.contractsList = [];
    this.vendorMasterService
      .getContracts(this.spId, this.startValue, this.EndValue)
      .subscribe((res: any) => {
        this.contractsList = res;
      });
  }

  getRecords(isFromAttrition?: boolean) {
    if (this.CurrentPage > 0) {
      this.startValue =
        (parseInt(this.CurrentPage) - 1) * parseInt(this.sortRecords);
    } else {
      this.startValue = 0;
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);
    if (!this.firstTimeLoad) {
      this.getData();
    }
    this.firstTimeLoad = false;
  }
}
