<div class="row">
  <div class="col-sm-6 trn-trc">
    <h5>Vendor {{ type }}</h5>
  </div>
</div>
<form [formGroup]="vendorForm">
  <div *ngIf="vendorForm?.value.id" class="mt-3">
    <app-tag
      [dataObj]="getTagObject(vendorForm?.value?.id)"
      [tagsIds]="vendorForm?.value?.tags"
    ></app-tag>
  </div>
  <app-tag
    [noIds]="true"
    *ngIf="!vendorForm?.value?.id"
    [tagsIds]="vendorForm?.value?.tags"
    (childTags)="getChildTags($event)"
  ></app-tag>

  <div class="row mt-3">
    <div class="col-sm-8">
      <mat-form-field appearance="outline" class="w-100 px-1">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Primary Contact Name</mat-label>
        <input matInput formControlName="primary_contact_name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Primary Contact Email</mat-label>
        <input matInput formControlName="primary_contact_email" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Domain Name</mat-label>
        <input matInput formControlName="domain_name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Short Name</mat-label>
        <input matInput formControlName="short_name" />
      </mat-form-field>
    </div>
    <div class="col-sm-4 text-center">
      <div class="image-hold" (click)="fileInput.click()" *ngIf="!imageUrl">
        <mat-icon class="upload-icon">upload</mat-icon>
      </div>
      <img
        *ngIf="imageUrl"
        class="image-hold"
        style="height: 150px; width: 200px"
        [src]="imageUrl"
        alt="Uploaded Image"
      />
      <button
        *ngIf="imageUrl"
        (click)="fileInput.click()"
        mat-button
        class="bg-primary text-white mt-2"
        mat-button
        mat-flat-button
      >
        Change Logo
      </button>
      <input
        hidden
        #fileInput
        type="file"
        id="file"
        (change)="onFileSelected($event)"
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12">
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Accelerator Type</mat-label>
        <input matInput formControlName="type" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Year Founded</mat-label>
        <input matInput formControlName="year" type="number" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Year of Incorp.</mat-label>
        <input matInput formControlName="year_of_incorp" type="number" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Current Location / HQ</mat-label>
        <input matInput formControlName="location" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Nearest Hub</mat-label>
        <input matInput formControlName="nearest_hub" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Sector</mat-label>
        <input matInput formControlName="sector" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Vendor Tier</mat-label>
        <input matInput formControlName="vendor_tier" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50">
        <mat-label>Vendor Type </mat-label>
        <mat-select
          appearance="filled"
          class="w-100"
          formControlName="vendor_type"
        >
          <mat-option *ngFor="let type of vendorTypeList" [value]="type.id">{{
            type.display_name
          }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50">
        <mat-label>Status</mat-label>
        <mat-select appearance="filled" class="w-100" formControlName="status">
          <mat-option
            *ngFor="let status of vendorStatusList"
            [value]="status.id"
            >{{ status.display_name }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Tax number 1</mat-label>
        <input matInput formControlName="tax_number_1" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Tax number 2</mat-label>
        <input matInput formControlName="tax_number_2" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Tax number 3</mat-label>
        <input matInput formControlName="tax_number_3" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Company code</mat-label>
        <input matInput formControlName="company_code" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Accounting Info</mat-label>
        <input matInput formControlName="accounting_info" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Payment Term</mat-label>
        <input matInput formControlName="payment_term" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Primary Contact Phone</mat-label>
        <input matInput formControlName="primary_contact_phone" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Secondary Contact Name</mat-label>
        <input matInput formControlName="secondary_contact_name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Secondary Contact Email</mat-label>
        <input matInput formControlName="secondary_contact_email" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Secondary Contact Phone</mat-label>
        <input matInput formControlName="secondary_contact_phone" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-form-field appearance="outline" class="w-100 px-1">
        <mat-label>Company Profile</mat-label>
        <textarea matInput formControlName="profile"></textarea>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Founder Name</mat-label>
        <input matInput formControlName="founder_name" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Founder Email</mat-label>
        <input matInput formControlName="founder_email" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Founder Contact No.</mat-label>
        <input matInput formControlName="contact_no" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Company Email</mat-label>
        <input matInput formControlName="email" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Category</mat-label>
        <input matInput formControlName="category" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-25 px-1">
        <mat-label>Area of Expertise</mat-label>
        <input matInput formControlName="area_expertise" />
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-50 px-1">
        <mat-label>Website Link</mat-label>
        <input matInput formControlName="link" />
      </mat-form-field>
      <!-- <mat-form-field appearance="outline" class="w-25 px-1" floatLabel="always">
                <mat-label>Client Partner Email</mat-label>
                <input matInput formControlName="primary_contact_email">
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-25 px-1" floatLabel="always">
                <mat-label>Client Partner Mobile</mat-label>
                <input matInput formControlName="primary_contact">
            </mat-form-field> -->
      <mat-checkbox formControlName="is_gdg" class="ml-4">Is GDP?</mat-checkbox>
    </div>
  </div>

  <div>
    <h6 class="mt-4">Whitelisted Users</h6>

    <div class="custom-table">
      <table class="table table-bordered">
        <thead style="position: sticky; top: 0; z-index: 1">
          <tr>
            <th class="fixed-header" scope="col">#</th>
            <th class="fixed-header" scope="col" class="w-30">First Name</th>
            <th class="fixed-header" scope="col" class="w-30">Last Name</th>
            <th class="fixed-header" scope="col" class="w-30">Email</th>
            <th class="fixed-header text-center col-2" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let option of vendorForm?.controls.users.controls;
              let i = index
            "
          >
            <tr [formGroup]="vendorForm?.controls.users.controls[i]">
              <td class="align-middle">{{ i + 1 }}</td>
              <td class="align-middle w-25">
                <mat-form-field
                  class="example-full-width"
                  style="width: 100%"
                  appearance="outline"
                >
                  <mat-label></mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="first_name"
                    name="first_name"
                  />
                </mat-form-field>
              </td>
              <td class="align-middle w-25">
                <mat-form-field
                  class="example-full-width"
                  style="width: 100%"
                  appearance="outline"
                >
                  <mat-label></mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="last_name"
                    name="last_name"
                  />
                </mat-form-field>
              </td>
              <td class="align-middle w-25">
                <mat-form-field
                  class="example-full-width"
                  style="width: 100%"
                  appearance="outline"
                >
                  <mat-label></mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="email"
                    name="email"
                  />
                </mat-form-field>
              </td>
              <td class="align-middle text-center">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <span class="cursor-pointer" (click)="removeUsers(i)">
                    <span class="cursor-pointer">
                      <img
                        src="./assets/images/comment-delete.svg"
                        style="width: 16px"
                      />
                    </span>
                  </span>

                  <span
                    class="cursor-pointer"
                    (click)="addUsers(i)"
                    *ngIf="vendorForm?.get('users')?.controls.length - 1 === i"
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="material-icons-filled"
                      class="cursor-pointer fs-5"
                      style="margin-top: 10px"
                      >add
                    </mat-icon>
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="vendorForm.get('users')?.controls?.length <= 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="w-25">
              <a class="add-btton" (click)="addUsers()">
                <mat-icon
                  aria-hidden="false"
                  aria-label="material-icons-filled"
                  class="cursor-pointer fs-5"
                  >add
                </mat-icon>
                Add Users
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="my-3">
    <h6 class="mt-4">Investment Commitment</h6>

    <div class="custom-table">
      <table class="table table-bordered">
        <thead style="position: sticky; top: 0; z-index: 1">
          <tr>
            <th class="fixed-header" scope="col">#</th>
            <th class="fixed-header" scope="col" class="w-30">Description</th>
            <th class="fixed-header" scope="col" class="w-30">Amount in ($)</th>
            <th class="fixed-header" scope="col" class="w-30">Valid Till</th>
            <th class="fixed-header text-center col-2" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-container
            *ngFor="
              let option of vendorForm?.controls.investments.controls;
              let i = index
            "
          >
            <tr [formGroup]="vendorForm?.controls.investments.controls[i]">
              <td class="align-middle">{{ i + 1 }}</td>
              <td class="align-middle w-25">
                <mat-form-field
                  class="example-full-width"
                  style="width: 100%"
                  appearance="outline"
                >
                  <mat-label></mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="description"
                    name="description"
                  />
                </mat-form-field>
              </td>
              <td class="align-middle w-25">
                <mat-form-field
                  class="example-full-width"
                  style="width: 100%"
                  appearance="outline"
                >
                  <mat-label></mat-label>
                  <input
                    type="text"
                    matInput
                    formControlName="amount"
                    name="amount"
                  />
                </mat-form-field>
              </td>
              <td class="align-middle w-25">
                <mat-form-field appearance="outline" class="w-100 py-1">
                  <mat-label class="label-design"></mat-label>
                  <input
                    matInput
                    [matDatepicker]="picker"
                    readonly
                    class="fw-500"
                    formControlName="valid_till"
                    fullWidth
                    status="basic"
                    (click)="picker.open()"
                    name="valid_till"
                  />
                  <mat-datepicker-toggle matSuffix [for]="picker">
                  </mat-datepicker-toggle>
                  <mat-datepicker #picker> </mat-datepicker>
                </mat-form-field>
              </td>
              <td class="align-middle text-center">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                  "
                >
                  <span class="cursor-pointer" (click)="removeInvestment(i)">
                    <span class="cursor-pointer">
                      <img
                        src="./assets/images/comment-delete.svg"
                        style="width: 16px"
                      />
                    </span>
                  </span>

                  <span
                    class="cursor-pointer"
                    (click)="addInvestment(i)"
                    *ngIf="
                      vendorForm?.get('investments')?.controls.length - 1 === i
                    "
                  >
                    <mat-icon
                      aria-hidden="false"
                      aria-label="material-icons-filled"
                      class="cursor-pointer fs-5"
                      style="margin-top: 10px"
                      >add
                    </mat-icon>
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="vendorForm.get('investments')?.controls?.length <= 0">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="w-25">
              <a class="add-btton" (click)="addInvestment()">
                <mat-icon
                  aria-hidden="false"
                  aria-label="material-icons-filled"
                  class="cursor-pointer fs-5"
                  >add
                </mat-icon>
                Add Investment
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>
<div class="row">
  <h6 class="mt-2">Contracts</h6>

  <div class="col-md-12">
    <div class="custom-height">
      <div
        class="custom-table table-responsive"
        style="max-height: calc(100vh - 220px)"
      >
        <table class="table table-bordered">
          <thead style="position: sticky; z-index: 1">
            <tr>
              <th
                *ngFor="let field of headerList"
                scope="col"
                class="text-center"
              >
                {{ field }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngFor="let cont of contractsList?.records; let i = index"
            >
              <tr>
                <td class="text-center">{{ startValue + i + 1 }}</td>
                <td>{{ cont?.contract_name }}</td>
                <td>{{ cont?.contract_type }}</td>
                <td>{{ cont?.invoice_type }}</td>
                <td>{{ cont?.contract_value }}</td>
                <td>{{ cont?.enterprise_contract_id }}</td>
                <td>{{ cont?.dir_gm }}</td>
                <!-- <td>{{cont?.slt}}</td> -->
                <td>{{ cont?.start_date }}</td>
                <td>{{ cont?.end_date }}</td>
              </tr>
            </ng-container>
          </tbody>
          <tr *ngIf="!contractsList?.total_count">
            <td colspan="14" class="noRecords text-center mt-2">
              <span class="mt-2"
                >{{ staticText.common.no_records_found }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div
      class="d-flex align-items-center justify-content-between"
      *ngIf="contractsList?.total_count > 0"
    >
      <div class="p-1">
        <div
          class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
        >
          <span class="p-1">Displaying</span>
          <select
            class="form-select form-select-sm table-sort-filter"
            aria-label="Default select example"
            [(ngModel)]="sortRecords"
            (ngModelChange)="getRecords()"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="150">150</option>
            <option value="200">200</option>
          </select>
          <span class="p-1">{{ contractsList?.total_count }}</span>
          <span class="p-1">records</span>
        </div>
      </div>
      <div class="p-1">
        <ngb-pagination
          [pageSize]="sortRecords"
          [collectionSize]="contractsList?.total_count"
          [maxSize]="2"
          [rotate]="true"
          [(page)]="CurrentPage"
          [boundaryLinks]="true"
          (pageChange)="getRecords()"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>
</div>
<div class="trn-trc-1 mt-4">
  <button
    mat-button
    mat-flat-button
    color="primary"
    class="bg-primary text-white"
    (click)="submitVendor()"
    aria-label="add"
  >
    Submit
  </button>
</div>
