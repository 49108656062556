import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import * as textConfiguration from "src/assets/static-text-configuration.json";
import { DatePipe } from "@angular/common";
import { FilterService } from "src/app/shared/services/filter.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { RateService } from "src/app/shared/services/rate.service";
import { VendorMasterService } from "src/app/shared/services/vendor-master.service";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";
@Component({
  selector: "app-list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  providers: [DatePipe],
  // encapsulation:ViewEncapsulation.None
})
export class ListComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  vendorList: any;
  sortRecords: any = 20;
  CurrentPage: any = 0;
  CurrentRatePage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  filterName: any = null;
  filterCategory: any = null;
  filterLocation: any = null;
  filterSector: any = null;
  filterArea: any = null;
  filterType: any = null;
  filterVendorType: any = null;
  filterStatus: any = null;
  filterTier: any = null;
  vendorTypeList: any = [];
  vendorStatusList: any = [];
  dataLoaded: boolean = false;

  constructor(
    private router: Router,
    private vendorMasterService: VendorMasterService,
    private spinner: NgxSpinnerService,
    private filterService: FilterService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private rateService: RateService,
    private breadCrumService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.getVendors();
    let breadCrumb = [
      { label: "Masters", url: "/prism/masters" },
      { label: "Vendor Master", url: "/vendor-master" },
    ];
    this.breadCrumService.setBreadcrumb(breadCrumb);
    this.getOptionsList();
  }

  getVendorTypeList() {
    this.vendorMasterService.getVendorTypes().subscribe((resp: any) => {
      this.vendorTypeList = resp;
    });
  }

  getVendorStatusList() {
    this.vendorMasterService.getVendorStatus().subscribe((resp: any) => {
      this.vendorStatusList = resp;
    });
  }

  getOptionsList() {
    this.getVendorTypeList();
    this.getVendorStatusList();
  }

  async getVendors() {
    this.spinner.show();
    this.vendorList = [];
    this.dataLoaded = false;
    await this.vendorMasterService.getVendorData().subscribe((res: any) => {
      this.spinner.hide();

      res.forEach((element: any) => {
        element.color = this.getGMapRandomColor();
      });

      this.vendorList = res;
      this.dataLoaded = true;
    });
  }

  getGMapRandomColor() {
    return "hsla(" + Math.floor(Math.random() * 360) + ", 50%, 40%, 100)";
  }

  navigateById(id: any) {
    this.router.navigate(["vendor-master/create-vendor/" + btoa(id)]);
  }

  getRateRecords(type: any) {
    if (this.CurrentRatePage > 0) {
      this.startValue =
        (parseInt(this.CurrentRatePage) - 1) * parseInt(this.sortRecords);
    } else {
      this.startValue = 0;
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);

    // if(this.activeRateTab == 0){
    //   this.getVendorRateList();
    // } else if(this.activeRateTab == 1){
    //   this.getMasterRateList();
    // }
  }

  deleteVendor(id: any) {
    let payload = {
      is_active: false,
    };
    this.vendorMasterService
      .deleteVendor(payload, id)
      .subscribe(async (res: any) => {
        if (res) {
          this.toastrService.success("Vendor deleted Successfully.");
          this.getVendors();
        }
      });
  }

  search() {
    this.vendorMasterService
      .getFilteredVendors(
        this.filterName,
        this.filterCategory,
        this.filterLocation,
        this.filterSector,
        this.filterArea,
        this.filterType,
        this.filterVendorType,
        this.filterStatus,
        this.filterTier
      )
      .subscribe((res: any) => {
        res.forEach((element: any) => {
          element.color = this.getGMapRandomColor();
        });

        this.vendorList = res;
      });
  }
}
